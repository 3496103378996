import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {path:"home", component: HomeComponent},
  {path:"", redirectTo: "/home", pathMatch:"full"},
  {path:"about", component: AboutComponent},
  {path:"product", loadChildren:() => import('./product/product.module').then(m => m.ProductModule)},
  {path:"vehicle", loadChildren:() => import('./vehicle/vehicle.module').then(m => m.VehicleModule)},
  {path:"repair", loadChildren:() => import('./repair/repair.module').then(m => m.RepairModule)},
  {path:"**", redirectTo:"", component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
