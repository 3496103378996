<div class="main">
    <h1>Our Mission</h1>
    <p>This website is primarily targeted at basic maintenance, hopefully providing people with an interest or desire to
        work on their cars for whatever reason to keep their car running well, for cheaper, as long as they can. We also
        strongly believe in open source and hope to publish our source data soon, enabling anyone to access as much
        repair data as we can provide;
        we hope by enabling consumers to work on their own car we can improve car ownership in the following three
        areas.
    </p>
    <div id="cost">
        <h2>Cost</h2>
        <p>Paying for someone to do work for you is generally a trade-off between time and money. The skills, knowledge,
            and tools that any skilled technician has gathered over time can easily be worth the price of trying to do a
            task yourself. However, there are also many things that are simple to learn, require few materials, and
            don't take much time. These kinds of tasks, like replacing bulbs, changing oil, or topping off your fluids
            (among others), are where you can save a lot of money.</p>
    </div>
    <div id="safety">
        <h2>Safety</h2>
        <div>
            A car that runs well is a safer car for everyone on the road. Take the following two examples, for instance:
            <ol>
                <li>The first time you almost hit or have almost been hit by someone who wasn't signaling a lane change,
                    you likely understood how helpful signal lights are (whether or not we're always good about it).
                    A simple bulb change allowing you to properly signal or improving your visibility costs just a few dollars and can take less than a minute, as compared to the potential thousands a crash would cost (not to mention insurance increases) and the time to repair your vehicle.
                </li>

                <li>Anyone who's ever broken down on the highway knows not only how nerve-wracking it can be (especially
                    if there's no median to pull off onto), but how much money a tow truck and mechanic's bill can be. Risking a collision because you can no longer move your vehicle or getting stranded somewhere remote is a safety risk most people would like to avoid.
                </li>
            </ol>
        </div>
    </div>
    <div id="ecology">
        <h2>Ecology (?)</h2>
        <div>
            <p>We don't have the math on this (if you have it we'd love to see it!), but from a high level, consider
                some of the requirements to build a new car:</p>
            <ul>
                <li>Sourcing raw materials</li>
                <li>Shipping and using those materials to create everything from a bare metal car chassis to a highly
                    complex touchscreen radio</li>
                <li>Shipping all those individual components to a manufacturer</li>
                <li>Assembling and testing the components in a factory</li>
                <li>Shipping the car out to your local car dealer</li>
            </ul>
            <p>It's not immediately obvious to us no matter how much efficiency your vehicle might gain, it will offset
                the ecological cost of discarding a working car that has already been produced and, when properly
                maintained, has years of life left. Regardless, what cannot be argued against is that letting a car
                prematurely die due to lack of maintenance will cost you money and waste what could have been a
                perfectly good car!</p>
        </div>
    </div>
</div>